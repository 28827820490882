import { getTarget } from '../helper-function/get-target';

export const initDefaultDropdowns = () => {
    // VARS
    const dropdownToggles = document.querySelectorAll('[data-dropdown-toggle]');
    const dropdownOptions = document.querySelectorAll('[data-dropdown-option]');
    const classOpenDropdown = 'is-opened';
    if (!dropdownToggles.length || !dropdownOptions.length) return;

    // LISTENERS
    [...dropdownToggles].forEach((toggle) => {
        toggle.addEventListener('click', handleOnClickDropdownToggle, false);
    });

    [...dropdownOptions].forEach((toggle) => {
        toggle.addEventListener('click', handleOnClickDropdownOption, false);
    });

    document.addEventListener('click', handleOnClickDocument, false);

    // HANDLERS
    function handleOnClickDropdownToggle() {
        toggleDropdown(this);
    }

    function handleOnClickDropdownOption() {
        const dropdown = this.closest('[data-dropdown]');
        setDropdownValue(dropdown, this);
    }

    function handleOnClickDocument(event) {
        if (!getTarget(event, '[data-dropdown]')) {
            closeDropDowns();
        }
    }

    // FUNCTIONS
    function toggleDropdown(toggle) {
        const dropdown = toggle.closest('[data-dropdown]');

        if (dropdown.classList.contains(classOpenDropdown)) {
            closeDropDown(dropdown);
        } else {
            openDropDown(dropdown);
        }
    }

    function setDropdownValue(dropdown, option) {
        const currentValue = dropdown.querySelector('[data-dropdown-value]');

        if (currentValue.textContent.trim() !== option.textContent.trim()) {
            currentValue.textContent = option.textContent.trim();
        }

        closeDropDown(dropdown);
    }

    function openDropDown(dropdown) {
        dropdown.classList.add(classOpenDropdown);
    }

    function closeDropDown(dropdown) {
        dropdown.classList.remove(classOpenDropdown);
    }

    function closeDropDowns() {
        const dropdowns = document.querySelectorAll('[data-dropdown]');

        [...dropdowns].forEach((dropdown) => {
            dropdown.classList.remove(classOpenDropdown);
        });
    }
};
