import Glide from '@glidejs/glide';

export const initTrialSevenComments = () => {
    const sliderSelector = '[data-trial-seven-comments]';
    const slider = document.querySelector(`${sliderSelector}`);
    const rtl = document.querySelector('.ar');
    if (!slider) return;

    const trialSevenComments = new Glide(`${sliderSelector}`, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        focusAt: 1,
        direction: rtl ? 'rtl' : 'ltr',
        autoplay: 4000,
    });

    trialSevenComments.mount();
};
