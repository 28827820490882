import { initBrandDropDown } from './dropdowns/initBrandDropDown';
import { initDefaultDropdowns } from './dropdowns/initDefaultDropdowns';
import { initHeaderDropdown } from './dropdowns/initHeaderDropdown';
import { initFooterDropdowns } from './dropdowns/initFooterDropdowns';

export const initDropdowns = () => {
    initBrandDropDown();
    initDefaultDropdowns();
    initHeaderDropdown();
    initFooterDropdowns();
};
