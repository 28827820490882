export const initBrandDropDown = () => {
    // VARS
    const dropDowns = document.querySelectorAll('[data-brand-dropdown]');
    const dropDownToggles = document.querySelectorAll('[data-brand-dropdown-current]');
    const dropDownListItems = document.querySelectorAll('[data-brand-dropdown-item]');
    const activeClassDropdown = 'brand-dropdown--is-open';
    const activeCrossClassPresenceOfFeature = 'comparison-table__value-cross';
    const activeArrowClassPresenceOfFeature = 'comparison-table__value-arrow';
    const hiddenClassDropdownItem = 'brand-dropdown__item--hidden';

    // LISTENERS
    dropDownToggles.forEach((dropDownToggle) => {
        dropDownToggle.addEventListener('click', handleOnClickToggle, false);
    });

    dropDownListItems.forEach((dropDownListItem) => {
        dropDownListItem.addEventListener('click', handleOnClickDownListItem, false);
    });

    document.addEventListener('click', handleOnClickDocument);
    document.addEventListener('scroll', handleOnScrollDocument);

    // HANDLERS
    function handleOnClickToggle() {
        toggleDropdown(this);
    }

    function handleOnClickDownListItem() {
        closeAllDropDown();
        changeValue(this);
    }

    function handleOnClickDocument(event) {
        if (!event.target.closest('[data-brand-dropdown]')) {
            closeAllDropDown();
        }
    }

    function handleOnScrollDocument() {
        closeAllDropDown();
    }

    // FUNCTIONS & METHODS
    function toggleDropdown(dropDownToggle) {
        dropDownToggle.closest('[data-brand-dropdown]').classList.contains(activeClassDropdown)
            ? closeDropDown(dropDownToggle)
            : openDropDown(dropDownToggle);
    }

    function openDropDown(tooltipToggle) {
        closeAllDropDown();
        tooltipToggle.closest('[data-brand-dropdown]').classList.add(activeClassDropdown);
    }

    function closeDropDown(tooltipToggle) {
        tooltipToggle.closest('[data-brand-dropdown]').classList.remove(activeClassDropdown);
    }

    function closeAllDropDown() {
        dropDowns.forEach((dropDown) => {
            dropDown.classList.remove(activeClassDropdown);
        });
    }

    function changeValue(option) {
        setSelectBrandValue(option);
        setPresenceOfFeature(option);
        setCurrentBrandPrice(option);
        setTooltipsFeatures(option);
        setNameFeatures(option);
    }

    function setSelectBrandValue(option) {
        const currentOption = option.closest('[data-brand-dropdown]').querySelector('[data-brand-dropdown-current]');
        const optionInner = option.innerHTML;

        dropDownListItems.forEach((dropDownListItem) => {
            dropDownListItem.classList.remove(hiddenClassDropdownItem);
        });
        option.classList.add(hiddenClassDropdownItem);

        currentOption.dataset.brandDropdownCurrent = option.dataset.brandDropdownItem;
        currentOption.innerHTML = optionInner;
    }

    function setPresenceOfFeature(option) {
        const presenceOfFeatures = JSON.parse(option.dataset.brandFeatures);
        const elemsPresenceFeatures = document.querySelectorAll('[data-presence-features]');

        elemsPresenceFeatures.forEach((elemPresenceFeature, index) => {
            if (presenceOfFeatures[index]) {
                elemPresenceFeature.classList.remove(activeCrossClassPresenceOfFeature);
                elemPresenceFeature.classList.add(activeArrowClassPresenceOfFeature);
            } else {
                elemPresenceFeature.classList.add(activeCrossClassPresenceOfFeature);
                elemPresenceFeature.classList.remove(activeArrowClassPresenceOfFeature);
            }
        });
    }

    function setCurrentBrandPrice(option) {
        document.querySelector('[data-brand-coast]').innerHTML = option.dataset.brandPrice;
    }

    function setTooltipsFeatures(option) {
        const tooltips = JSON.parse(option.dataset.brandFeaturesTooltips);
        const tableTooltips = document.querySelectorAll('[data-brand-features-tooltip]');

        tableTooltips.forEach((tooltip, index) => {
            tooltip.textContent = tooltips[index];
        });
    }

    function setNameFeatures(option) {
        const featureNames = JSON.parse(option.dataset.featureName);
        const tableFeatureNames = document.querySelectorAll('[data-table-feature-name]');

        tableFeatureNames.forEach((featureName, index) => {
            featureName.textContent = featureNames[index];
        });
    }
};
