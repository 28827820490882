import Swiper from 'swiper/bundle';

export function initStatisticCardsSlider() {
    const slider = document.querySelector('[data-statistics-slider]');
    if (!slider) return;
    const sliderContents = document.querySelectorAll('[data-slide-content]');

    const swiper = new Swiper('[data-statistics-slider]', {
        speed: 400,
        loop: true,
        pagination: {
            el: '[data-statistics-slider-pagination]',
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: '[data-statistics-slider-button-next]',
            prevEl: '[data-statistics-slider-button-prev]',
        },
        breakpoints: {
            320: {
                slidesPerView: 2.5,
                spaceBetween: 16,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 16,
            },
            1440: {
                slidesPerView: 6,
                spaceBetween: 20,
            },
        },
    });

    swiper.on('slideChange', function () {
        const currentSlide = this.slides[this.activeIndex];
        changeContentSlider(+currentSlide.dataset.slide);
    });

    function changeContentSlider(index) {
        const activeClassName = 'spy-apps-statistics__slider-content--active';
        sliderContents.forEach((sliderContent) => {
            sliderContent.classList.remove(activeClassName);
        });
        sliderContents[index].classList.add(activeClassName);
    }
}
