export function initGoogleAuth() {
    // Vars
    const btnGoogleAuth = document.querySelector('[data-btn-google-auth]');
    if (!btnGoogleAuth) return;
    const agreeCheckbox = document.querySelector('[data-create-account-checkbox]');
    const form = document.querySelector('[data-create-account-form]');
    const classShowErrorMessage = 'is-error';
    const googleAuthHref = btnGoogleAuth.dataset.googlePath;

    // Listener
    btnGoogleAuth.addEventListener('click', handleOnClick, false);

    // Handler
    function handleOnClick() {
        validateAgreeCheckbox();
    }

    // Function
    function validateAgreeCheckbox() {
        hideErrorMessage(agreeCheckbox);

        if (agreeCheckbox.checked) {
            hideErrorMessage(agreeCheckbox);
            window.location.href = googleAuthHref;
        } else {
            showErrorMessage(agreeCheckbox, 'checkbox');
        }
    }

    function hideErrorMessage() {
        const field = form.querySelector('[data-field-error="checkbox"]');

        field.classList.remove(classShowErrorMessage);
    }

    function showErrorMessage() {
        const field = form.querySelector('[data-field-error="checkbox"]');

        field.classList.add(classShowErrorMessage);
    }
}
