import Swiper from 'swiper/bundle';

export function initFeatureTabSlider() {
    // VARS
    const sliderTabNavElem = document.querySelector('[data-features-tab-nav]');
    const sliderTabContentElem = document.querySelector('[data-features-tab-content-init]');
    const tabButtons = document.querySelectorAll('[data-features-tab-button]');
    if (!sliderTabNavElem || !sliderTabContentElem) return;

    const activeClassTabContent = 'spy-apps-feature__tab-content--visible';
    const activeClassTabNavButton = 'spy-apps-feature__tab-nav-button--active';
    let sliderTabNav;
    let sliderTabContent;

    // CALLS
    toggleInitTabNavSlider();
    if (document.body.clientWidth > 767) {
        toggleTabContent(1);
        initTabContentSliders(1);
    }

    // LISTENERS
    window.addEventListener('resize', handleOnResizeWindow, false);

    tabButtons.forEach((button) => {
        button.addEventListener('click', handleOnClickTabNavButton, false);
    });

    // HANDLERS
    function handleOnResizeWindow() {
        toggleInitTabNavSlider();
    }

    function handleOnClickTabNavButton() {
        if (document.body.clientWidth > 767) {
            toggleTabContent(this.dataset.featuresTabButton);
            toggleActiveNavButton(this.dataset.featuresTabButton);
            initTabContentSliders(this.dataset.featuresTabButton);
        }
    }

    // FUNCTIONS & METHODS
    function toggleInitTabNavSlider() {
        if (document.body.clientWidth < 767 && sliderTabNavElem.dataset.featuresTabNav !== 'init') {
            sliderTabNavElem.dataset.featuresTabNav = 'init';

            sliderTabNav = new Swiper('[data-features-tab-nav]', {
                speed: 400,
                loop: false,
                slidesPerView: 1.5,
                spaceBetween: 20,
                centeredSlides: true,
                on: {
                    init: function () {
                        const button = this.slides[this.activeIndex].querySelector('[data-features-tab-button]');
                        toggleTabContent(button.dataset.featuresTabButton);
                        initTabContentSliders(button.dataset.featuresTabButton);
                    },
                    slideChange: function () {
                        const button = this.slides[this.activeIndex].querySelector('[data-features-tab-button]');
                        toggleTabContent(button.dataset.featuresTabButton);
                        initTabContentSliders(button.dataset.featuresTabButton);
                    },
                },
            });
        } else if (document.body.clientWidth >= 768 && sliderTabNavElem.dataset.featuresTabNav === 'init') {
            sliderTabNavElem.dataset.featuresTabNav = 'destroy';
            sliderTabNav?.destroy(true, true);
        }
    }

    function initTabContentSliders(indexTabNav) {
        const slider = document.querySelector(`[data-features-tab-content="${indexTabNav}"]`);

        sliderTabContent && sliderTabContent.destroy();

        sliderTabContent = new Swiper(`[data-features-tab-content="${indexTabNav}"]`, {
            speed: 400,
            loop: false,
            slidesPerView: 2.2,
            spaceBetween: 10,
            pagination: {
                el: '[data-feature-card-slider-pagination]',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '[data-feature-card-slider-button-next]',
                prevEl: '[data-feature-card-slider-button-prev]',
            },
            breakpoints: {
                640: {
                    slidesPerView: 3.5,
                    spaceBetween: 50,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
            },
        });
    }

    function toggleTabContent(indexTabNav) {
        const tabContents = document.querySelectorAll('[data-features-tab-content]');

        tabContents.forEach((tabContent, index) => {
            index + 1 === +indexTabNav
                ? tabContent.closest('[data-feature-list-slider]').classList.add(activeClassTabContent)
                : tabContent.closest('[data-feature-list-slider]').classList.remove(activeClassTabContent);
        });
    }

    function toggleActiveNavButton(indexTabNav) {
        tabButtons.forEach((tabButton, index) => {
            index + 1 === +indexTabNav
                ? tabButton.classList.add(activeClassTabNavButton)
                : tabButton.classList.remove(activeClassTabNavButton);
        });
    }
}
