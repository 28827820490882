import Swiper from 'swiper';

export const initRecommendationSlider = () => {
    const slider = document.querySelector('[data-recommendation-slider]');
    if (!slider) return;

    const swiper = new Swiper('[data-recommendation-slider]', {
        speed: 400,
        pagination: {
            el: '[data-recommendation-slider-pagination]',
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: '[data-recommendation-slider-button-next]',
            prevEl: '[data-recommendation-slider-button-prev]',
        },
        breakpoints: {
            320: {
                slidesPerView: 1.25,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 1.75,
                spaceBetween: 16,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
    });
};
