import { initStatisticCardsSlider } from './sliders/initStatisticCardsSlider';
import { initFeatureTabSlider } from './sliders/initFeatureTabSlider';
import { initRecommendationSlider } from './sliders/initRecommendationSlider';
import { initDefaultGlideSlider } from './sliders/initDefaultGlideSlider';
import { initSliderReviews } from './sliders/initSliderReviews';
import { initTrialSevenComments } from './sliders/initTrialSevenComments';
import { initFreeRegFeaturesSlider } from './sliders/initFreeRegFeaturesSlider';
import { initFeaturesSlider } from './sliders/initFeaturesSlider';

export const initSliders = () => {
    initStatisticCardsSlider();
    initFeatureTabSlider();
    initRecommendationSlider();
    initDefaultGlideSlider();
    initSliderReviews();
    initTrialSevenComments();
    initFreeRegFeaturesSlider();
    initFeaturesSlider();
};
