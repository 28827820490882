import HystModal from 'hystmodal';

export const oneDayTrialModal = () => {
    const oneDayTrialModal = new HystModal({
        linkAttributeName: 'data-open-modal-one-day-trial',
        catchFocus: true,
        waitTransitions: true,
        closeOnEsc: false,
    });
};
