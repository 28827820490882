export const initTooltip = () => {
    // VARS
    const tooltips = document.querySelectorAll('[data-tooltip]');
    const tooltipToggles = document.querySelectorAll('[data-tooltip-toggle]');
    const activeClassTooltip = 'tooltip--visible';

    // LISTENERS
    tooltipToggles.forEach((tooltipToggle) => {
        tooltipToggle.addEventListener('click', handleOnClickTooltipToggle, false);
    });

    document.addEventListener('click', handleOnClickDocument);

    // HANDLERS
    function handleOnClickTooltipToggle() {
        if (window.innerWidth < 1280) {
            toggleTooltip(this);
        }
    }

    function handleOnClickDocument(event) {
        if (!event.target.closest('[data-tooltip]')) {
            closeAllTooltip();
        }
    }

    // FUNCTIONS & METHODS
    function toggleTooltip(tooltipToggle) {
        tooltipToggle.closest('[data-tooltip]').classList.contains(activeClassTooltip)
            ? closeTooltip(tooltipToggle)
            : openTooltip(tooltipToggle);
    }

    function openTooltip(tooltipToggle) {
        closeAllTooltip();
        tooltipToggle.closest('[data-tooltip]').classList.add(activeClassTooltip);
    }

    function closeTooltip(tooltipToggle) {
        tooltipToggle.closest('[data-tooltip]').classList.remove(activeClassTooltip);
    }

    function closeAllTooltip() {
        tooltips.forEach((tooltip) => {
            tooltip.classList.remove(activeClassTooltip);
        });
    }
};
