import ScrollMagic from 'scrollmagic';

export const initDefaultAnimation = () => {
    const scrollMagicController = new ScrollMagic.Controller();
    const animateBlocks = document.querySelectorAll('[data-animate-block]');

    animateBlocks.forEach((animateBlock) => {
        const scene = new ScrollMagic.Scene({
            triggerElement: animateBlock,
            reverse: false,
            triggerHook: 0.7,
        })
            .setClassToggle(animateBlock, 'is-animated')
            .addTo(scrollMagicController);
    });
};
